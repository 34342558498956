import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

export const Circle = styled.div`
    width: 70vh;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    
`

const Heading = keyframes`
  from { 
       width: 0;
     }
   
     to { 
       width: 15ch;
     }
`;

export const Text = styled.h1`
    font-family: courier-std, monospace;
    font-weight: 700;
    font-style: normal;
    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
    animation: ${Heading} 3s steps(15) forwards infinite;
`