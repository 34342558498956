import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/hero"

export interface IndexProps {}

const IndexPage: React.FC<IndexProps> = () => (
  <Layout>
    <Seo title="Ophelia Studio | Coming soon..." />
    <Hero />
  </Layout>
)

export default IndexPage
