import * as React from 'react';
import { Wrapper, Circle, Text } from './styles';

export interface HeroProps {
    
}
 
const Hero: React.SFC<HeroProps> = () => {
    return ( 
        <Wrapper>
            <Circle>
                <Text>Coming soon...</Text></Circle>
        </Wrapper>
 );
}
 
export default Hero;